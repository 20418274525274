import { Col, Container, Row } from "react-bootstrap";

const Condiciones = () => {
  return (
    <Container>
      <Row>
        <Col className="pt-5 pb-5">
          <h2>Bases y Condiciones</h2>
          <p>
            <div className="pt-4" />
            <strong>Ford</strong>
            <br />
            Consultar en{" "}
            <a href="https://www.ford.com.ar/" target="_blank" rel="noopener noreferrer">
              https://www.ford.com.ar/
            </a>{" "}
            <br />
            <br />
            <div className="pt-4" />
            <strong>Fiat</strong>
            <br />
            Consultar en{" "}
            <a href="https://www.fiat.com.ar/" target="_blank" rel="noopener noreferrer">
              https://www.fiat.com.ar/
            </a>{" "}
            <br />
            <br />
            <div className="pt-4" />
            <strong>Jeep</strong>
            <br />
            Consultar en{" "}
            <a href="https://www.jeep.com.ar/" target="_blank" rel="noopener noreferrer">
              https://www.jeep.com.ar/
            </a>{" "}
            <br />
            <br />
            <div className="pt-4" />
            <strong>Peugeot</strong>
            <br />
            Consultar en{" "}
            <a href="https://www.peugeot.com.ar/" target="_blank" rel="noopener noreferrer">
              https://www.peugeot.com.ar/
            </a>{" "}
            <br />
            <br />
            <div className="pt-4" />
            <strong>Hyundai</strong>
            <br />
            Consultar en{" "}
            <a href="https://www.hyundai.com.ar/" target="_blank" rel="noopener noreferrer">
              https://www.hyundai.com.ar/
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Condiciones;
